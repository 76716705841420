<script>
    import DashboardHub from '../pages/DashboardHub';
    import Presale from '../pages/Presale';

    const routes = [
        { path: '*', redirect: '/dashboard' },
        { path: '/dashboard', redirect: '/presale' },//Temp set to presale until we got the whole app running
        { path: '/presale', component: Presale },
    ];

    export default routes;
</script>
