import getWeb3 from '../../utils/getWeb3';

// initial state
const state = () => ({
    isInjected: false,
    isInitialised: false,
    networkId: null,
    error: null,
    instance: null
});

// getters
const getters = {};

// mutations
const mutations = {
    initialised(state) {
        state.isInitialised = true;
    },

    setInstance(state, instance) {
        state.instance = instance;
    },

    setNetworkId(state, id) {
        state.networkId = id;
    }
};

// actions
const actions = {
    async initialise({ commit, dispatch }) {
        getWeb3().then((res) => {
            commit('setInstance', Object.freeze(res));
            commit('initialised');
            dispatch('getNetworkId');
        }).catch((err) => {
            console.log(err, 'err!!');
        });
    },

    async getNetworkId({ commit, state }) {
        commit('setNetworkId', await state.instance.eth.net.getId());
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};