<template>
    <div><!-- This div is the <router-view  id="content" class="app-content {{ $appContentClass }}"></router-view> from sap.blade.php -->
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {

        data() {
            return {
                requiredContracts: [],
                requiredPairs: [],
            }
        },

        created() {
        },

        updated () {
        },

        computed: {
            ...mapState([])
        },

        watch: {
        },

        methods: {
            initiate: function() {
            }
        },

        mounted() {
            this.initiate();
        }
    }
</script>