<template>
    <div><!-- This div is the <router-view  id="content" class="app-content {{ $appContentClass }}"></router-view> from sap.blade.php -->
        <div class="row justify-content-center" v-if="loaded.contracts.includes('ZOMBIE')">
            <div class="col-12 col-xl-9 col-xxl-7">
                <div class="panel bg-dark text-white">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="panel bg-dark border border-purple text-white mb-0">
                                    <div class="panel-body">
                                        <div class="row justify-content-center mb-3">
                                            <div class="col-10">
                                                <h1 class="text-center ft-se text-green">PRESALE</h1>
                                                <p class="text-justify fs-4">
                                                    <span><i class="fas fa-hand-point-right text-green"></i> Click 'connect wallet'.</span><br>
                                                    <span><i class="fas fa-hand-point-right text-green"></i> To participate in the HORDE presale, you need to purchase a pHORDE token. This is limited to 1 pHORDE token per wallet.</span><br>
                                                    <span><i class="fas fa-hand-point-right text-green"></i> This token can be swapped for a HORDE plot at launch.</span><br>
                                                    <span><i class="fas fa-hand-point-right text-green"></i> Click Approve (below) to approve wallet.</span><br>
                                                    <span><i class="fas fa-hand-point-right text-green"></i> Once approved, click 'BUY 1 pHORDE'.</span><br>
                                                    <span><i class="fas fa-hand-point-right text-green"></i> Be sure to have BNB for gas fees.</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-12 col-md-4">
                                                <p class="text-center p-3 border border-purple rounded">
                                                    <span class="fs-1"><i class="fa fa-coins text-green"></i></span><br>
                                                    <span class="fs-2">{{stats.forSale}}</span><br>
                                                    <span class="fs-6">pHORDE left</span>
                                                </p>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <p class="text-center p-3 border border-purple rounded">
                                                    <span class="fs-1"><i class="fa fa-vault text-green"></i></span><br>
                                                    <span class="fs-2">{{wallet.balances.ZOMBIE}}</span><br>
                                                    <span class="fs-6">pHORDE you have</span>
                                                </p>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <p class="text-center p-3 border border-purple rounded">
                                                    <span class="fs-1"><i class="fa fa-circle-dollar-to-slot text-green"></i></span><br>
                                                    <span class="fs-2">500 BUSD</span><span class="fs-2 d-none">1 TC2</span><br>
                                                    <span class="fs-6">pHORDE sale price</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mb-3 d-none">
                                            <div class="col-12 text-center fs-4">
                                                You currently have: {{wallet.balances.ZOMBIE}} pHORDE<br>
                                                {{contracts.ZOMBIE.decimals}}<br>
                                                {{contracts.ZOMBIE.allowance}}<br>
                                                {{contracts.ZOMBIE.root}}<br>
                                                {{stats.forSale}}<br>
                                                {{stats.tokensToBeSold}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="progress">
                                                    <div class="progress-bar bg-green text-dark" v-bind:style="'width: '+((Number(stats.tokensToBeSold) - Number(stats.forSale)) * 100 / Number(stats.tokensToBeSold))+'%;'">
                                                        {{((Number(stats.tokensToBeSold) - Number(stats.forSale)) * 100 / Number(stats.tokensToBeSold)).toFixed(0)}} %
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <span class="float-start text-muted fs-6">{{(Number(stats.tokensToBeSold) - Number(stats.forSale))}} pHORDE</span>
                                                <span class="float-end text-muted fs-6">{{Number(stats.tokensToBeSold)}} pHORDE</span>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center mb-3">
                                            <div class="col-8 col-md-6 col-xl-4">
                                                <button class="btn btn-grad-purple w-100"
                                                        v-if="!active"
                                                        disabled>
                                                    PRESALE IS NOT LIVE
                                                </button>
                                                <button class="btn btn-grad w-100"
                                                        v-on:click="approve"
                                                        v-if="active && contracts.ZOMBIE.allowance === '0' && Number(stats.forSale) !== 0">
                                                    {{processing ? 'Processing...' : 'Approve'}}
                                                </button>
                                                <button class="btn btn-grad w-100"
                                                        v-on:click="deposit"
                                                        v-if="active && contracts.ZOMBIE.allowance > 0 && Number(stats.forSale) !== 0">
                                                    {{processing ? 'Processing...' : 'Buy 1 pHORDE'}}
                                                </button>
                                                <button class="btn btn-grad-purple w-100"
                                                        v-if="active && Number(stats.forSale) === 0"
                                                        disabled>
                                                    SOLD OUT
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="d-grid gap-2 d-md-block text-center">
                                                <a target="_tab" href="https://twitter.com/HordeTweets" class="btn btn-grad mx-2"><i class="fa-brands fa-twitter"></i></a>
                                                <a target="_tab" href="https://discord.gg/horde" class="btn btn-grad mx-2"><i class="fa-brands fa-discord"></i></a>
                                                <a target="_tab" href="https://www.reddit.com/r/Horde_Official/" class="btn btn-grad mx-2"><i class="fa-brands fa-reddit-alien"></i></a>
                                                <a target="_tab" href="https://t.me/HordeOfficialChannel" class="btn btn-grad mx-2"><i class="fa-brands fa-telegram"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {

        data() {
            return {
                pHORDE: {
                    totalSupply: 0,
                    balance: 0,
                },
                requiredContracts: ["pHORDE", "BUSD", "ZOMBIE"],
                requiredPairs: [],
                loaded: {
                    contracts: []
                },
                processing: false,
                stats: {
                    forSale: null,
                    tokensToBeSold: null,
                    maxBuy: null,
                },
                active: true,
            }
        },

        created() {
            this.$eventBus.$on("contracts/instantiateContract", (event) => {
                if (!this.requiredContracts.includes(event.payload)) {return;}
                this.$root.$children[0].verifyRequestConditionsAndContinueOrDelay('getWalletBalance', event.payload);
            });

            this.$eventBus.$on("wallet/getWalletBalance", (event) => {
                if (!this.requiredContracts.includes(event.payload)) {return;}
                this.$root.$children[0].verifyRequestConditionsAndContinueOrDelay('callAllowanceAmount', event.payload);
            });

            this.$eventBus.$on("updateAllowanceAmount", (event) => {
                if (!this.requiredContracts.includes(event.payload)) {return;}
                if (this.loaded.contracts.includes(event.payload)) {return;}
                this.loaded.contracts.push(event.payload);
                if (event.payload === 'ZOMBIE') {this.updatePresale();}
            });
        },

        updated () {
        },

        computed: {
            ...mapState(['contracts', 'pairs', 'session', 'wallet', 'web3'])
        },

        watch: {
            "session.isConnected": function(newVal, oldVal) {
                if (newVal === true && oldVal === false) {this.initiate();}
            }
        },

        methods: {
            initiate: function() {
                this.$root.$children[0].verifyRequestConditionsAndContinueOrDelay('getRequiredContracts', this.requiredContracts);
                this.$root.$children[0].verifyRequestConditionsAndContinueOrDelay('getRequiredPairs', this.requiredPairs);
            },

            approve: async function() {
				if (this.processing === false) {
					this.processing = true;
					try {
						await this.$calls.approve(this.wallet.account, false, this.contracts.ZOMBIE.address, this.contracts.BUSD);
					} catch (e) {
						console.log(e);
						this.processing = false;
						return;
					}
					this.processing = false;
                    this.$nextTick(function() {
                        this.$root.$children[0].verifyRequestConditionsAndContinueOrDelay('callAllowanceAmount', 'ZOMBIE');
                    });
				}
            },

            deposit: async function() {
				if (this.processing === false) {
					this.processing = true;
					try {
						await this.contracts.ZOMBIE.instance.methods.deposit().send({ from: this.wallet.account });
					} catch (e) {
						console.log(e);
						this.processing = false;
						return;
					}
					this.processing = false;
                    this.$root.$children[0].verifyRequestConditionsAndContinueOrDelay('getWalletBalance', 'ZOMBIE');
				}
            },

            getStats: async function() {
                this.stats.forSale = this.web3.instance.utils.fromWei(await this.contracts.ZOMBIE.instance.methods.balanceOf(this.contracts.ZOMBIE.address).call());
                this.stats.tokensToBeSold = this.web3.instance.utils.fromWei(await this.contracts.ZOMBIE.instance.methods.totalSupply().call());
                // this.active = await this.contracts.ZOMBIE.instance.methods.active().call();
            },

            updatePresale: function() {
                this.getStats();
                let self = this;
                setTimeout(function() {
                    if (['/presale', '/presale/'].includes(self.$route.path)) {self.updatePresale();}
                }, 1000);
            },
        },

        mounted() {
            this.initiate();
        }
    }
</script>