<template>
	<div class="app" v-if="!appOptions.appEmpty" v-bind:class="{
		'app-header-fixed': appOptions.appHeaderFixed && !appOptions.appHeaderNone,
		'app-sidebar-fixed': appOptions.appSidebarFixed,
		'app-sidebar-minified': appOptions.appSidebarMinified, 
		'app-content-full-height': appOptions.appContentFullHeight, 
		'app-without-sidebar': appOptions.appSidebarNone, 
		'app-with-end-sidebar': appOptions.appSidebarEnd, 
		'app-with-two-sidebar': appOptions.appSidebarTwo,
		'app-with-wide-sidebar': appOptions.appSidebarWide,
		'app-with-light-sidebar': appOptions.appSidebarLight,
		'app-sidebar-mobile-toggled': appOptions.appSidebarMobileToggled,
		'app-sidebar-end-toggled': appOptions.appSidebarEndToggled,
		'app-sidebar-end-collapsed': !appOptions.appSidebarEndToggled,
		'app-sidebar-end-mobile-toggled': appOptions.appSidebarEndMobileToggled,
		'app-without-header': appOptions.appHeaderNone,
		'app-with-top-menu': appOptions.appTopMenu,
		'app-gradient-enabled': appOptions.appGradientEnabled,
		'has-scroll': appOptions.appBodyScrollTop
	}">
		<Header />
		<TopMenu v-if="appOptions.appTopMenu" />
		<Sidebar v-if="!appOptions.appSidebarNone" />
		<SidebarRight v-if="appOptions.appSidebarTwo" />
		<div class="app-cover">
            <div class="fogwrapper">
                <div id="foglayer_01" class="fog">
                    <div class="image01"></div>
                    <div class="image02"></div>
                </div>
                <div id="foglayer_02" class="fog">
                    <div class="image01"></div>
                    <div class="image02"></div>
                </div>
                <div id="foglayer_03" class="fog">
                    <div class="image01"></div>
                    <div class="image02"></div>
                </div>
            </div>
        </div>
        

        

		<div id="content" class="app-content" v-bind:class="appOptions.appContentClass">
			<router-view></router-view>
			<vue-ins-progress-bar></vue-ins-progress-bar>
		</div>
		<SidebarRight v-if="appOptions.appSidebarTwo" />
		<ScrollTopBtn />
        <button class="d-none" id="toggleDisclaimerModal" data-bs-toggle="modal" data-bs-target="#disclaimerModal"></button>
        <!-- #modal-dialog -->
        <div class="modal fade bg-gray-200 bg-opacity-20" id="disclaimerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-gray-900">
                    <div class="modal-header">
                        <h4 class="modal-title text-white">Disclaimer</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-12 text-white">
                                <p class="text-justify">
                                    Not Financial Advice<br>
                                    The information provided on this dApp, website, marketing materials, discord, twitter or any other affiliated HORDE assets or what could be perceived to be HORDE assets is not to be construed or taken as investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of this content as such. HORDE does not recommend that any cryptocurrency or blockchain technology should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decision.
                                </p>
                                <p class="text-justify">
                                    Accuracy of Information<br>
                                    HORDE will strive to ensure accuracy of information listed on this website but we do not hold any responsibility for any missing or wrong information. HORDE provides this information as is. You understand that you are using any and all information here at your own risk.
                                </p>
                                <p class="text-justify">
                                    KYC, Age and Sound Mind Policy<br>
                                    By using these services you hereby submit that you are of sound mind and are not currently using and or have been using any substance that could inhibit your judgment. Further, that you are of legal age and are able to participate in this experiment. Lastly, that you are not violating any IDO/KYC legislation based upon your current geographical location. The following is an example of territories that have rules around the use of these types of projects. It is not meant to include all territories but only as an example: The United States, China, Russia, Parts of Europe, etc. By accepting the following you acknowledge that you are abiding by any and all local authorities rules and regulations and assume all responsibility for the use of this dApp. You hold harmless HORDE and all its affiliates.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-grad" v-on:click="acceptDisclaimer">I accept</button>
                    </div>
                </div>
            </div>
        </div>
	</div>
	<div class="h-100" v-else>
		<router-view></router-view>
		<vue-ins-progress-bar></vue-ins-progress-bar>
	</div>
    
</template>

<script>
import Sidebar from './components/sidebar/Sidebar.vue'
import SidebarRight from './components/sidebar-right/SidebarRight.vue'
import Header from './components/header/Header.vue'
import TopMenu from './components/top-menu/TopMenu.vue'
import ScrollTopBtn from './components/scroll-top-btn/ScrollTopBtn.vue'
import AppOptions from './config/AppOptions.vue'

import appOptions from './config/Options';
import sidebarMenu from './config/SideBar';
import requestsConditions from './config/RequestsConditions';
import { mapState } from 'vuex';

export default {
	name: 'app',
	components: {
		Sidebar,
		SidebarRight,
		Header,
		TopMenu,
		ScrollTopBtn
	},

	data() {
		return {
			appOptions: AppOptions,
			//System
			displayDecimals: 3,
			config: {
				appOptions: appOptions,
				sidebarMenu: sidebarMenu,
			},
			requestsConditions: requestsConditions,
			requestsTries: {},
            counter: 0,
		}
	},

	created() {
		this.getSession();

		AppOptions.appBodyScrollTop = window.scrollY;
		
		window.addEventListener('scroll', this.handleScroll);
		
		this.$insProgress.start();
		
		this.$router.beforeEach((to, from, next) => {
			this.$insProgress.start();
			next();
		});
		this.$router.afterEach(() => {
			this.$insProgress.finish();
			AppOptions.appSidebarMobileToggled = false;
		});

        // this.$store.subscribeAction((action, state) => {
        //     if (action.type.split('/')[0] !== 'events') {return;}
        //     this.$eventBus.$emit(action.type, {payload: action.payload});
        // });
        this.$store.subscribeAction({
            after: (action, state) => {
                // if (action.type.split('/')[0] !== 'events') {return;}
                this.$eventBus.$emit(action.type, {payload: action.payload});
            }
        });
	},

    watch: {
        "$route": function(to, from) {this.$eventBus.$emit('routeChanged');},

        "session.isConnected": function(newVal, oldVal) {
            if (oldVal !== null) {this.putSession();}
            if (oldVal === null && newVal === true && this.wallet.account === undefined) {this.verifyRequestConditionsAndContinueOrDelay('getWalletAccount');} //In case the website authorisation got revoke and the session tell it's connected, reset state.
        },

        "web3.isInitialised": function() {
            this.verifyRequestConditionsAndContinueOrDelay('getWalletAccount');
        },
    },

    computed: mapState(['contracts', 'pairs', 'session', 'wallet', 'web3']),

	methods: {
		handleScroll: function() {
			AppOptions.appBodyScrollTop = window.scrollY;
		},

        // // [START] - Request Condition
        // // This is a helper that verify if a function can be executed, if not, it's delayed further in the stack.
        verifyRequestConditionsAndContinueOrDelay: function(request, payload = false) {
            if (this.requestsTries[request] === undefined) {this.requestsTries[request] = 0;}
            // console.log(request+' || tries: '+this.requestsTries[request]);
            if (this.verifyGlobalConditions() && this.verifyRequestConditions(request, payload)) {
                this.requestsTries[request] = 0;
                this.$nextTick(function() {
                    this[request](payload);
                });
                return;
            }
            if (this.requestsTries[request] < this.requestsConditions.config.tries) {
                let self = this;
                setTimeout(function() {
                    self.verifyRequestConditionsAndContinueOrDelay(request, payload)
                }, this.requestsConditions.config.timer);
                this.requestsTries[request]++;
                return;
            }
            console.log('This request have been stop: '+request);
            console.log(payload);
            this.requestsTries[request] = 0;
        },

        verifyGlobalConditions: function() {
            return true;
        },

        verifyRequestConditions: function(request, payload) {
            if (this.requestsConditions.requests[request] === undefined) {return true;}
            for (var condition of this.requestsConditions.requests[request].conditions) {
                if (condition.type === 'equal' && this[condition.module][condition.state] !== condition.value) {return false;}
                if (condition.type === 'notEqual' && this[condition.module][condition.state] === condition.value) {return false;}
                if (condition.type === 'getters' && this.$store.getters[condition.module+'/'+condition.state](payload) !== condition.value) {return false;}
                if (condition.type === 'gettersRoot' && this.$store.getters[condition.module+'/'+condition.state](this.$store.state.contracts[payload].root) !== condition.value) {return false;}
            }
            return true;
        },
        // // [END] - Request Condition

        // [START] - Session Handler
        // This part is used to sync the wallet connection. The wallet connection is responsible to either show or not the data.

        // getSession : The purpose of this funtion is too retrieve the value of the wallet connection from the sesssion store onto the back send server.
        getSession: async function() {
            // var response = await this.$api.getFunction('/session/get'); //Get the current state of session isConnected
            // if (response.data === 1) {response.data = true;} else {response.data = false;} //Transform server response to fit Javascript
            // this.$store.commit('session/setConnection', response.data); //Store the response inside the store session state.
            // if (this.session.isInitialised === false) {this.$store.commit('session/setInitialisation');} //Tell the app that the session has been initialised.
            this.$store.commit('session/setConnection', true);
            if (this.session.isInitialised === false) {this.$store.commit('session/setInitialisation');}
        },

        // putSession : The purpose of this function is to send value of the wallet connection to back end server and store it into the session of the user.
        putSession: function() {
            // this.$api.putFunction('/session/put', {isConnected: this.session.isConnected}); //Put the current state of the session isConnected (is the wallet connected) into the server.
        },
        // [END] - Session Handler

        getWalletAccount: async function(firstOccurence = false) {
            await this.$store.dispatch('wallet/getAccount');
            if (this.wallet.account !== undefined && firstOccurence === false) {
                this.$eventBus.$emit('walletAccountIsSet');
                return;
            }
            if (this.wallet.account === undefined && firstOccurence === true) { //The first occurence is forced if the session connection was set to true. This condition will be filled if the web3 authorization was revoked.
                this.$store.commit('session/setConnection', false); //Force session to not connect, because authorization was revoked.
            }
        },

        connectWallet: async function() {
            if (this.wallet.account === undefined) {
                try {
                    await window.ethereum.enable();
                } catch (error) {
                    return;
                }
            }
            await this.getWalletAccount();
            this.$store.commit('session/setConnection', true);
        },

        disconnectWallet: async function() {
            this.$store.commit('session/setConnection', false);
            // this.resetDatas();
        },

        getRequiredContracts: async function(requiredContracts) {//This need to be seperate getRequiredContracts and getRequiredBalances...
            if (this.session.isConnected === false) {return;}
            for(var contract of requiredContracts) {
                this.$store.dispatch('contracts/instantiateContract', contract);
            }
        },

        getWalletBalance: async function(contract) {
            this.$store.dispatch('wallet/getWalletBalance', contract);
        },

        getRequiredPairs: async function(requiredPairs) {
            if (this.session.isConnected === false) {return;}
            for(var pair of requiredPairs) {
                await this.$store.dispatch('pairs/instantiatePair', pair);
                this.$nextTick(function() {
                    this.$eventBus.$emit('updatePairRatio', {pair: pair});
                });
            }
        },

        getRequiredPools: async function(requiredPools) {
            if (this.session.isConnected === false) {return;}
            for(var pool of requiredPools) {
                this.$store.dispatch('pools/instantiatePool', pool);
            }
        },

        callAllowanceAmount: async function(contract) {
            var response = await this.$calls.allowance(this.$store.state.wallet.account, false, this.$store.state.contracts[contract].address, this.$store.state.contracts[this.$store.state.contracts[contract].root]);
            if (response === undefined) {return;}
            await this.$store.commit('contracts/setAllowance', {contract: contract, allowance: this.$store.state.web3.instance.utils.fromWei(response)});
            this.$eventBus.$emit('updateAllowanceAmount', {payload: contract});
        },

        getRequiredForms: async function(requiredForms) {
            if (this.session.isConnected === false) {return;}
            for(var form of requiredForms) {
                await this.$store.dispatch('forms/instantiateForm', form);
                this.$nextTick(function() {
                    this.$eventBus.$emit('updateForm', {form: form});
                });
            }
        },

        notify: function() {
            this.$notify({
                group: 'foo',
                title: 'Important message',
                text: 'Hello user! This is a notification!'
            });
        },

        acceptDisclaimer: function() {
            this.$cookies.set('disclaimer', true);
            document.getElementById('toggleDisclaimerModal').click();
        }
	},

	mounted () {
		this.$insProgress.finish();
        this.$store.dispatch('web3/initialise');
        if (!this.$cookies.get('disclaimer')) {
            document.getElementById('toggleDisclaimerModal').click();
        }
        console.log('disclaimer: ' + this.$cookies.get('disclaimer'));
	},
}
</script>
